.feed {
    flex: 0.45;
    border-right: 1px solid var(--twitter-background);
    min-width: fit-content;
    overflow-y: scroll;
}


/* Hide scrollbar for Chrome, Safari and Opera */

.feed::-webkit-scrollbar {
    display: none;
}


/* Hide scrollbar for IE, Edge and Firefox */

.feed {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.feed__header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
    border: 10px solid var(--twiiter-background);
    padding: 15px 20px;
}