.tweetBox {
    padding-bottom: 10px;
    padding-right: 10px;
    border-bottom: 8px solid var(--twitter-background);
}

.tweetBox>form {
    display: flex;
    flex-direction: column;
}

.tweetBox__input {
    padding: 20px;
    display: flex;
}

.tweetBox__input>input {
    flex: 1;
    margin-left: 20px;
    font-size: 20px;
    border: none;
}

.tweetBox_imageInput {
    border: none;
    padding: 10px;
}

.tweetBox__tweetButton {
    background-color: var(--twitter-color) !important;
    border: none !important;
    color: white !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    border-radius: 30px !important;
    width: 80px !important;
    height: 50px !important;
    margin-top: 20px !important;
    margin-left: auto !important;
}