body {
    --twitter-color: #50b7f5;
    --twitter-background: #e6ecf0;
}

.app {
    display: flex;
    height: 100vh;
    /* to make the border tough the bottom */
    max-width: 1300px;
    margin: 0 auto;
}